<template>
  <v-card
    height="100%"
  >
    <v-toolbar
      flat
      width="100%"
      class="toolbar"
    >
      <v-btn
        icon
        rounded
        @click.stop="$router.back()"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-toolbar-title class="font-weight-light">
        {{ $t('mixed|general_search') }}
      </v-toolbar-title>
    </v-toolbar>
    <div
      class="mt-4 mx-4 search-field"
    >
      <v-text-field
        v-model="searchInput"
        dense
        prepend-inner-icon="mdi-magnify"
        append-icon="mdi-close"
        @click:append="searchInput = ''"
      />
    </div>
    <div
      class="text-center"
      style="position: absolute; right: 50%; margin-top: 30%;"
    >
      <v-progress-circular
        v-show="isLoading"
        color="warning"
        width="4"
        indeterminate
        size="40"
      />
    </div>
    <v-card-subtitle
      v-if="!isLoading && searchInput.length > 2 && _clients.length===0 && _cases.length===0 && _caseModels.length===0 && _templates.length===0 && _documents.length===0 && _folders.length===0 && _members.length===0"
      class="my-2 ml-2 font-weight-light"
    >
      {{ $t('common|no_results_found') }}
    </v-card-subtitle>
    <v-card-text
      v-else
      class="flex-grow-1 py-1 px-3"
    >
      <v-list
        subheader
        two-line
        style="background-color: #FAFAFA"
      >
        <div v-if="_clients.length === 0" />
        <div
          v-else
          class="my-4"
        >
          <v-col
            xs="12"
            md="6"
          >
            <span inset>
              {{ $t('clients|clients') }}
            </span>
          </v-col>

          <v-col
            v-for="(client, clientIdx) in _clients"
            :key="`client-${clientIdx}`"
            :hide-close-icon-option="true"
            cols="12"
            class="pa-0"
          >
            <div class="item-width">
              <client-item
                :key="client._id"
                :client="client"
                :has-selection="false"
                :has-actions="true"
                @open-client="openClient"
              />
            </div>
          </v-col>
          <v-divider />
        </div>
        <div v-if="_cases.length===0" />
        <div
          v-else
          class="my-4"
        >
          <v-col
            xs="12"
            md="6"
          >
            <span inset>
              {{ $t('cases|cases') }}
            </span>
          </v-col>

          <v-col
            v-for="(c, caseIdx) in _cases"
            :key="`case-${caseIdx}`"
            :hide-close-icon-option="true"
            cols="12"
            class="pa-0"
          >
            <div class="item-width">
              <case-item
                :key="c._id"
                :case-prop="c"
                :has-selection="false"
                :has-actions="true"
                @open-case="openCase"
              />
            </div>
          </v-col>
          <v-divider />
        </div>

        <div v-if="_caseModels.length === 0" />
        <div
          v-else
          class="my-4"
        >
          <v-col
            xs="12"
            md="6"
          >
            <span inset>
              {{ $t('case_models|case_models') }}
            </span>
          </v-col>

          <v-col
            v-for="(model, modelIdx) in _caseModels"
            :key="`model-${modelIdx}`"
            :hide-close-icon-option="true"
            cols="12"
            class="pa-0"
          >
            <div class="item-width">
              <case-models-item
                :key="model._id"
                :case-model="model"
                :has-selection="false"
                :has-actions="true"
                @open-case-model="openCaseModel"
              />
            </div>
          </v-col>
          <v-divider />
        </div>

        <div v-if="_templates.length === 0" />
        <div
          v-else
          class="my-4"
        >
          <v-col
            xs="12"
            md="6"
          >
            <span inset>
              {{ $t('templates|templates') }}
            </span>
          </v-col>

          <v-col
            v-for="(template, tempIdx) in _templates"
            :key="`template-${tempIdx}`"
            :hide-close-icon-option="true"
            cols="12"
            class="pa-0"
          >
            <div class="item-width">
              <template-item
                :key="template._id"
                :template="template"
                :has-selection="false"
                :has-actions="true"
                :has-search-actions="true"
                @open-template="openTemplate"
              />
            </div>
          </v-col>
          <v-divider />
        </div>

        <div v-if="_documents.length===0" />
        <div
          v-else
          class="my-4"
        >
          <v-col
            xs="12"
            md="6"
          >
            <span inset>
              {{ $t('documents|documents') }}
            </span>
          </v-col>

          <v-col
            v-for="(doc, docIdx) in _documents"
            :key="`document-${docIdx}`"
            :hide-close-icon-option="true"
            cols="12"
            class="pa-0"
          >
            <div class="item-width">
              <document-item
                :key="doc._id"
                :document="doc"
                :has-selection="false"
                :has-actions="false"
                :has-search-actions="true"
                :has-doc-icons="false"
                @click.native="openDocument(doc, 'from-general-search')"
              />
            </div>
          </v-col>
          <v-divider />
        </div>

        <div v-if="_folders.length===0" />
        <div
          v-else
          class="my-4"
        >
          <v-col
            xs="12"
            md="6"
          >
            <span inset>
              {{ $t('folders|folders') }}
            </span>
          </v-col>

          <v-col
            v-for="(fol, folIdx) in _folders"
            :key="`folder-${folIdx}`"
            :hide-close-icon-option="true"
            cols="12"
            class="pa-0"
          >
            <div class="item-width">
              <v-list-item
                style="cursor: pointer;"
                @click.native="openFolder(fol)"
              >
                <v-list-item-icon>
                  <v-icon
                    class="mt-3"
                  >
                    mdi-folder-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ fol.folderName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-col>
          <v-divider />
        </div>

        <div v-if="_members.length===0" />
        <div
          v-else
          class="my-4"
        >
          <v-col
            xs="12"
            md="6"
          >
            <span inset>
              {{ $t('members|members') }}
            </span>
          </v-col>

          <v-col
            v-for="(member, memIdx) in _members"
            :key="`member-${memIdx}`"
            :hide-close-icon-option="true"
            cols="12"
            class="pa-0"
          >
            <div class="item-width">
              <member-item
                :key="member._id"
                :member="member"
                :has-selection="false"
                :has-actions="true"
                @open-members="openMembersSection()"
              />
            </div>
          </v-col>
        </div>
      </v-list>
    </v-card-text>
    <FileView
      ref="fileView"
      :is-visible="isVisible"
      :from-general-search="true"
    />
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { generalSearch } from '@/utils/search.js'
import { uniqBy } from '@/utils/utils'
import FileView from '@/components/dialogs/FileView.vue'
import generalMixin from '@/utils/generalMixin.js'

export default {
  components: { FileView },
  mixins: [generalMixin],
  props: {
    isVisible: {
      type: Boolean,
      default: undefined
    },
    fromRibbonPanel: {
      type: Boolean,
      default: undefined
    }
  },
  data () {
    return {
      dialog: false,
      isLoading: false,
      foundResults: {},
      hoverCloseButtonIcon: false,
      searchInput: ''
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      storeCases: state => state.cases.cases,
      storeCompanyCases: state => state.companyCases.companyCases,
      storeGroupCases: state => state.groupCases.groupCases,
      storeCaseModels: state => state.caseForms.caseForms,
      storeCompanyCaseModels: state => state.companyCaseForms.companyCaseForms,
      storeGroupCaseModels: state => state.groupCaseForms.groupCaseForms
    }),
    _clients () {
      if (!this.foundResults || !this.foundResults.clients) return []
      const mappedClientResults = this.foundResults.clients.map((client) => {
        return {
          ...client,
          clientName: (client && client.clientType === 'individual') ? (client.given_names + ' ' + client.surname) : client.company_name
        }
      })
      const uniqMappedClientResults = uniqBy(mappedClientResults, '_id')
      return uniqMappedClientResults
    },
    _cases () {
      if (!this.foundResults || !this.foundResults.cases) return []
      const mappedCasesResults = this.foundResults.cases.map((c) => {
        return {
          ...c
        }
      })
      const uniqmappedCasesResults = uniqBy(mappedCasesResults, '_id')
      return uniqmappedCasesResults
    },
    _caseModels () {
      if (!this.foundResults || !this.foundResults.case_models) return []
      const mappedCaseModelsResults = this.foundResults.case_models.map((model) => {
        return {
          ...model
        }
      })
      const uniqMappedCaseModelsResults = uniqBy(mappedCaseModelsResults, '_id')
      return uniqMappedCaseModelsResults
    },
    _templates () {
      if (!this.foundResults || !this.foundResults.templates) return []
      const mappedTempResults = this.foundResults.templates.map((template) => {
        return {
          ...template
        }
      })
      const uniqMappedTempResults = uniqBy(mappedTempResults, '_id')
      return uniqMappedTempResults
    },
    _documents () {
      if (!this.foundResults || !this.foundResults.documents) return []
      const mappedDocResults = this.foundResults.documents.map((doc) => {
        return {
          ...doc,
          docName: doc.raw.originalname
        }
      })
      const uniqMappedDocResults = uniqBy(mappedDocResults, '_id')
      return uniqMappedDocResults
    },
    _folders () {
      if (!this.foundResults || !this.foundResults.folders) return []
      const mappedFoldersResults = this.foundResults.folders.map((fol) => {
        return {
          ...fol,
          folderName: fol.raw.originalname
        }
      })
      const uniqMappedFoldersResults = uniqBy(mappedFoldersResults, '_id')
      return uniqMappedFoldersResults
    },
    _members () {
      if (!this.foundResults || !this.foundResults.members) return []
      const mappedMemResults = this.foundResults.members.map((member) => {
        return {
          ...member
        }
      })
      const uniqMappedResults = uniqBy(mappedMemResults, '_id')
      return uniqMappedResults
    }
  },
  watch: {
    searchInput (searchInput) {
      if (!searchInput || searchInput === '' || this.searchInput === null) {
        this.foundResults = {}
        return
      }
      this.searchPython(searchInput)
      this.isLoading = true
    },
    foundResults (value) {
      if (Object.keys(value).length) {
        this.isLoading = false
      }
      if (
        this.searchInput.length > 2 &&
        value.clients.length === 0 &&
        value.cases.length === 0 &&
        value.case_models.length === 0 &&
        value.templates.length === 0 &&
        value.documents.length === 0 &&
        value.members.length === 0 &&
        value.folders.length === 0
      ) {
        this.isLoading = false
      }
    }
  },
  methods: {
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    async searchPython () {
      const result = await generalSearch(this.searchInput)
      this.foundResults = result
    },
    openClient (client) {
      this.$router.push({ path: `/clients/${client._id}` })
    },
    openCase (_case) {
      const actualCase = this.storeCases.find(_c => _c._id === _case._id) || this.storeCompanyCases.find(_c => _c._id === _case._id) || this.storeGroupCases.find(_c => _c._id === _case._id)
      this.$router.push({ path: `/cases/${actualCase._id}/${actualCase.workbenches[0]._id}` })
    },
    openCaseModel (caseModel) {
      const actualCaseModel = this.storeCaseModels.find(_cm => _cm._id === caseModel._id) || this.storeCompanyCaseModels.find(_cm => _cm._id === caseModel._id) || this.storeGroupCaseModels.find(_cm => _cm._id === caseModel._id)
      this.$router.push({ path: `/models/${actualCaseModel._id}/${actualCaseModel.workbenches[0]._id}` })
    },
    openTemplate (template) {
      this.$router.push({ path: `/templates/${template._id}` })
    },
    openMembersSection () {
      this.$router.push({ path: `/members` })
    },
    openFolder (fol) {
      this.$router.push({ path: `/documents/folders/${fol._id}` })
    }
    // openDocument could be found in generalMixin => utils
  }
}
</script>

<style scoped>
  .toolbar {
    position: sticky;
    top: 45px;
    z-index: 1;
  }
  .search-field {
    background-color: white;
    position: sticky;
    top: 55px;
    z-index: 1;
  }
  .item-width {
    max-width: 600px;
  }
  .item-width :hover {
    white-space: normal;
  }
</style>
